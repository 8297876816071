<template>
  <v-row class="centro">
    <v-col class="align-center">
      <v-data-table
        dense
        :headers="headers"
        :items="bairros"
        item-key="id"
        height="auto"
        locale="pt-BR"
        class="elevation-10 mb-10"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar id="tb" flat>
            <svg-icon height="30px" width="30px" type="mdi" :path="icone"></svg-icon>
            <v-toolbar-title>Cadastro de Bairros de {{ cidade.nome }}-{{ cidade.uf }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <svg-icon class="mt-2" type="mdi" :path="pesquisar"></svg-icon>
            <v-text-field
              v-model="search"
              label="Pesquisar"
              class="ml-1 mt-7 mr-7"
            ></v-text-field>
            <v-btn
              color="black"
              dark
              class="mb-2"
              @click="newItem()"
            >
              Novo Bairro
            </v-btn>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  Tem certeza que deseja excluir o bairro
                  {{editedItem.nome}}?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    Excluir
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          Ainda não existem dados cadastrados
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="editItem(item)" class="mr-4" icon color="black">
            <svg-icon type="mdi" :path="editar"></svg-icon>
          </v-btn>
          <v-btn @click="deleteItem(item)" class="mr-4" icon color="black">
            <svg-icon type="mdi" :path="excluir"></svg-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-btn class="botoes" color="blue darken-1" text @click="retornarCadastro">
            Retornar
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import svcBairros from "../../services/svcBairros";
import svcCidades from "../../services/svcCidades";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiPencil, mdiDelete, mdiMagnify, mdiSitemap } from '@mdi/js';
import { decriptar, encriptar } from "../../config/cripto";


export default {
  components: {
        SvgIcon
    },
  data() {
    return {
      editar: mdiPencil,
      excluir: mdiDelete,
      pesquisar: mdiMagnify,
      icone: mdiSitemap,
      search: "",
      bairros: [],
      headers: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Ações", align: "center", value: "actions", sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: "",
        id_cidade: "",
        nome: "",
      },
      defaultItem: {
        id: "",
        id_cidade: "",
        nome: "",
      },
      cidade: {
          id: "",
          nome: "",
          uf: "",
        },
      isSelecting: false,
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    buscaCidade(id) {
      svcCidades
        .pesquisar(id)
        .then((resp) => {
          this.cidade = resp.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
  
    lista(id_cidade) {
      svcBairros
        .listar(id_cidade)
        .then((resp) => {
          this.bairros = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    newItem() {
     this.$router.push("/bairros/new/" + encriptar(this.cidade.id));
    },

    editItem(item) {
     this.$router.push("/bairros/edit/" + encriptar(this.cidade.id) + "/" + encriptar(item.id));
    },

    deleteItem(item) {
      this.editedIndex = this.bairros.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      svcBairros
        .excluir(this.editedItem.id)
        .then((resp) => {
          if (resp.status == 200) {
            this.lista(this.cidade.id);
          } else {
            alert("Erro excluindo um bairro.");
          }
        })
        .catch((error) => {
          alert(error);
        });
      this.closeDelete();
    },

    retornarCadastro() {
     this.$router.push("/cidades");
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

  mounted() {
    this.buscaCidade(decriptar(this.$route.params.id_cidade));
    this.lista(decriptar(this.$route.params.id_cidade));
  },
};
</script>

<style scoped>
#tb {
  background-color: white;
  /* #dbdbdb; */
}
</style>
