import { render, staticRenderFns } from "./ConsultaBairros.vue?vue&type=template&id=556b40bc&scoped=true&"
import script from "./ConsultaBairros.vue?vue&type=script&lang=js&"
export * from "./ConsultaBairros.vue?vue&type=script&lang=js&"
import style0 from "./ConsultaBairros.vue?vue&type=style&index=0&id=556b40bc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556b40bc",
  null
  
)

export default component.exports